import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copy", "input", "tooltip"];

  connect() {
    this.inputTarget.addEventListener("click", this._selectAllText);
  }

  copy() {
    this.inputTarget.select();
    document.execCommand("copy");

    const originalTooltipText = this.tooltipTarget.innerText;
    this.tooltipTarget.innerText = "Copied!";
    setTimeout(() => {
      this.tooltipTarget.innerText = originalTooltipText;
    }, 2000);
  }

  _selectAllText(e) {
    e.target.select();
  }
}
