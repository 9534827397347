import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "count"];
  static values = { max: Number };

  connect() {
    this.update();
  }

  update() {
    const length = this.inputTarget.value.length;
    this.countTarget.textContent = length;

    const isOver = length > this.maxValue;
    this.countTarget.classList.toggle("text-red-700", isOver);

    if (isOver) {
      this.inputTarget.setCustomValidity("Character limit exceeded");
    } else {
      this.inputTarget.setCustomValidity("");
    }
  }
}
