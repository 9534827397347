import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "label"];

  connect() {
    this.index = this.findSelectedIndex();
    this.updateDisplay();
  }

  findSelectedIndex() {
    return this.radioTargets.findIndex((target) => {
      return target.querySelector("input").checked;
    });
  }

  updateDisplay() {
    const currentButton = this.radioTargets[this.index];
    if (currentButton) {
      this.labelTarget.textContent =
        currentButton.querySelector("label").textContent;
    }

    const baseCost = parseInt(this.element.dataset.baseCost);

    // get current value
    const currentInputValue = currentButton.querySelector("input").value;
    const creditsCost = (currentInputValue / 2) * baseCost + baseCost;

    // update credits display
    this.element
      .closest("form")
      .querySelectorAll(".js-credit-cost")
      .forEach((element) => {
        element.textContent = creditsCost;
      });

    // compare current user credits to cost
    const currentUserCredits = parseInt(this.element.dataset.userCredits);

    if (currentUserCredits >= creditsCost) {
      this.element
        .closest("form")
        .querySelector(".js-submit")
        .classList.remove("hidden");
      this.element
        .closest("form")
        .querySelector(".js-buy-credits")
        .classList.add("hidden");
    } else {
      this.element
        .closest("form")
        .querySelector(".js-submit")
        .classList.add("hidden");
      this.element
        .closest("form")
        .querySelector(".js-buy-credits")
        .classList.remove("hidden");
    }
  }

  previous() {
    this.index =
      (this.index - 1 + this.radioTargets.length) % this.radioTargets.length;
    this.updateSelection();
  }

  next() {
    this.index = (this.index + 1) % this.radioTargets.length;
    this.updateSelection();
  }

  updateSelection() {
    this.radioTargets.forEach((target, index) => {
      const input = target.querySelector("input");
      if (index === this.index) {
        input.checked = true;
      }
    });
    this.updateDisplay();
  }
}
