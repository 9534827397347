import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slide", "next", "previous"];

  connect() {
    this.index = 0;
    this._showCurrentSlide();
    this._toggleButtons();
  }

  next() {
    if (this.index === this.slideTargets.length - 1) {
      return;
    }

    this.index++;
    this._toggleButtons();
    this._showCurrentSlide();
  }

  previous() {
    if (this.index === 0) {
      return;
    }

    this.index--;
    this._toggleButtons();
    this._showCurrentSlide();
  }

  _showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("opacity-0", this.index != i);
    });
  }

  _toggleButtons() {
    if (this.index === 0) {
      this.previousTarget.disabled = true;
      this.previousTarget.classList.add("hidden");
    }

    if (this.index === this.slideTargets.length - 1) {
      this.nextTarget.disabled = true;
      this.nextTarget.classList.add("hidden");
    }

    if (this.index > 0) {
      this.previousTarget.disabled = false;
      this.previousTarget.classList.remove("hidden");
    }

    if (this.index < this.slideTargets.length - 1) {
      this.nextTarget.disabled = false;
      this.nextTarget.classList.remove("hidden");
    }
  }
}
