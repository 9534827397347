import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollArea", "pagination"];

  connect() {
    this._createObserver();
  }

  _createObserver() {
    const observer = new IntersectionObserver(
      (entries) => this._handleIntersect(entries),
      {
        threshold: [0, 1.0],
      }
    );

    observer.observe(this.scrollAreaTarget);
  }

  _handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this._loadMore();
      }
    });
  }

  _loadMore() {
    const next = this.paginationTarget.querySelector("[aria-label=Next]");

    if (!next.href) {
      return;
    }

    const href = next.href;
    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
